import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';

import {StripeProvider} from 'react-stripe-elements';
import {Stripe} from 'stripe.js'
import Checkout from '../../components/checkout';

import Online from '../../pics/web_development_online_bootcamp_barcelona_code_school.jpg'

const InpersonDepToOnline = () => (

	<div className="white grid-1-3"
	>
	<Helmet

	title="Web / Mobile Development Online Bootcamp payments page"
	meta={[
		{ name: 'description', content: 'BCS Payments page' },
		{ name: 'keywords', content: 'payment form' },
		{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
		]}
		script={[
			{ "src": "https://js.stripe.com/v3/" }
			]}
			/>
			<div>
			<h1>Transfer from Web / Mobile Development In-person to Online Bootcamp in Barcelona Code School</h1>
			<h2>Payment form: 580€ deposit paid</h2>
			</div>

			<div style={{
				marginTop: '2em',

			}}>
			<img 
			className='banners'
			src={Online} 
			alt='Web / Mobile Development Online Bootcamp in Barcelona Code School'/>

			</div>



			

			<h2 id="cohorts">Access</h2>
			<div>
			<div className="event" >
			<h4>1520€</h4>
			<span>
			<h2>Tuition payment</h2>
			</span>
			<span>
			<p>580€ deposit paid</p>

			<Link to="/register" state={{course: "Transfer from Web / Mobile Development In-person to Online Bootcamp", date:'According to contract', cost:1520}}>
			<button>Proceed to checkout</button>
			</Link>
			</span>
			</div>
			
			

			
			</div>




			</div>


			)

export default InpersonDepToOnline
